import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';

import { ScaOrdenCargaProgramacionListadoProjection } from '~models/carga/projection/ScaOrdenCargaProgramacionListadoProjection';
import { ScaOrdenCargaProjection } from '~models/carga/projection/ScaOrdenCargaProjection';
import { ScaOrdenCargaTransbordoDto } from '~models/carga/interface/ScaOrdenCargaTransbordo';
import { SearchScaOrdenCargaDto } from '~models/carga/dto/SearchScaOrdenCargaDto';
import { SearchScaOrdenCargaProgramacionCustomDto } from '~models/carga/dto/SearchScaOrdenCargaProgramacionCustomDto';
import { ScaRoutingDto } from '~models/carga/interface/ScaRouting';

@Injectable({
	providedIn: 'root',
})
export class ScaOrdenCargaService {
	private readonly _url = `${environment.HOST_CARGA}/scaordencargas`;

	scaOrdenCargaTransbordo: BehaviorSubject<Partial<ScaOrdenCargaTransbordoDto>> = new BehaviorSubject<Partial<ScaOrdenCargaTransbordoDto>>({});

	scaRouting: BehaviorSubject<Partial<ScaRoutingDto>> = new BehaviorSubject<Partial<ScaRoutingDto>>({});

	/**
	 * Devuelve la instancia de la clase {@code ScaOrdenCargaProjection}
	 */
	scaOrdenCargaProjection: Subject<ScaOrdenCargaProjection> = new Subject();

	constructor(private http: HttpClient) {}

	findById(id: number): Observable<any> {
		return this.http.get<ApiResponse>(`${this._url}/model-se-ro/${id}`).pipe(map((res) => res.data));
	}

	findCustomDto(search: SearchScaOrdenCargaDto): Observable<ScaOrdenCargaProjection> {
		return this.http.post<ApiResponse>(`${this._url}/model-se-ro/findCustomDto`, search).pipe(map((res) => res.data as ScaOrdenCargaProjection));
	}

	findAllProgramacionCustomDto(search: SearchScaOrdenCargaProgramacionCustomDto): Observable<ScaOrdenCargaProgramacionListadoProjection[]> {
		return this.http.post<ApiResponse>(`${this._url}/model-se-ro/findAllProgramacionCustomDto`, search).pipe(map((res) => res.data as ScaOrdenCargaProgramacionListadoProjection[]));
	}
}
